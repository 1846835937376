import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrayerTimesApp from './PrayerTimesApp';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AdminLoginPage from './pages/AdminLoginpage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AppRedirectPage from './pages/AppRedirectPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* PrayerTimesApp Routes */}
        <Route path="/*" element={<PrayerTimesApp />} />

        {/* Admin Routes */}
        <Route path="/admin-login" element={<AdminLoginPage />} />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        
        {/* App Redirect Route */}
        <Route path="/get-app" element={<AppRedirectPage />} />
      </Routes>
    </Router>
  );
};

export default App;
