import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { PrayerTimesData } from '../utils/types';
import {
  isFriday,
  isHijriMonthChange,
  getHijriMonths,
  getHijriDayInfo,
  formatShortDayMonth,
  formatMonthYear
} from '../utils/monthUtils';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Helvetica',
    backgroundColor: '#FFFBF5',
  },
  header: {
    marginBottom: 15,
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 24,
    marginBottom: 5,
    fontWeight: 'bold',
    color: '#2C3E50',
  },
  subtitle: {
    fontSize: 14,
    color: '#34495E',
    marginBottom: 5,
  },
  monthDisplay: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#34495E',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#34495E',
    minHeight: 18,
  },
  fridayRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#34495E',
    minHeight: 18,
    backgroundColor: '#EBF5FB',
  },
  hijriMonthChangeRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#34495E',
    minHeight: 18,
    backgroundColor: '#EBF5FF',
  },
  tableHeader: {
    backgroundColor: '#34495E',
    color: '#FFFFFF',
    padding: 4,
    fontSize: 9,
    flex: 1,
    textAlign: 'center',
  },
  tableCell: {
    padding: 3,
    fontSize: 8.5,
    flex: 1,
    textAlign: 'center',
  },
  dateCell: {
    padding: 3,
    fontSize: 8.5,
    flex: 1.2,
    textAlign: 'left',
  },
  hijriInfo: {
    fontSize: 7,
    color: '#34495E',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 8,
    color: '#34495E',
  },
  qrSection: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 40,
  },
  qrContainer: {
    width: 150,
    alignItems: 'center',
  },
  qrCode: {
    width: 80,
    height: 80,
  },
  qrLabel: {
    fontSize: 10,
    color: '#34495E',
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 2,
    fontWeight: 'bold',
  },
  qrDescription: {
    fontSize: 8,
    color: '#34495E',
    textAlign: 'center',
    maxWidth: 140,
  },
});

interface MonthData {
  date: Date;
  data: PrayerTimesData;
  daysInMonth: number;
}

interface PrayerTimesPDFProps {
  months: MonthData[];
}

const PrayerTimesPDF: React.FC<PrayerTimesPDFProps> = ({ months }) => {
  const renderMonthPage = ({ date, data, daysInMonth }: MonthData) => (
    <Page key={format(date, 'yyyy-MM')} size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Mjölby Moské Bönetider</Text>
        <View style={styles.monthDisplay}>
          <Text style={styles.subtitle}>
            {formatMonthYear(date)}
          </Text>
          <Text style={styles.subtitle}>-</Text>
          <Text style={styles.subtitle}>
            {getHijriMonths(date, daysInMonth)}
          </Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableHeader}>Datum</Text>
          <Text style={styles.tableHeader}>Fajr</Text>
          <Text style={styles.tableHeader}>Shuruq</Text>
          <Text style={styles.tableHeader}>Dhuhr</Text>
          <Text style={styles.tableHeader}>Asr</Text>
          <Text style={styles.tableHeader}>Maghrib</Text>
          <Text style={styles.tableHeader}>Isha</Text>
        </View>
        
        {Array.from({ length: daysInMonth }).map((_, index) => {
          const currentDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            index + 1
          );
          const dateString = format(currentDate, 'yyyy-MM-dd');
          const prayerTimes = data[dateString];
          const friday = isFriday(currentDate);
          const hijriChange = isHijriMonthChange(currentDate);
          
          return (
            <View 
              key={dateString} 
              style={
                hijriChange ? styles.hijriMonthChangeRow : 
                friday ? styles.fridayRow : 
                styles.tableRow
              }
            >
              <Text style={styles.dateCell}>
                {formatShortDayMonth(currentDate)}
                <Text style={styles.hijriInfo}>
                  {hijriChange ? 
                    ` ${getHijriDayInfo(currentDate)}` : 
                    friday ? ' (Fredag)' : 
                    ''
                  }
                </Text>
              </Text>
              <Text style={styles.tableCell}>{prayerTimes?.Fajr?.slice(0, 5)}</Text>
              <Text style={styles.tableCell}>{prayerTimes?.Sunrise?.slice(0, 5)}</Text>
              <Text style={styles.tableCell}>{prayerTimes?.Dhuhr?.slice(0, 5)}</Text>
              <Text style={styles.tableCell}>{prayerTimes?.Asr?.slice(0, 5)}</Text>
              <Text style={styles.tableCell}>{prayerTimes?.Maghrib?.slice(0, 5)}</Text>
              <Text style={styles.tableCell}>{prayerTimes?.Isha?.slice(0, 5)}</Text>
            </View>
          );
        })}
      </View>

      <View style={styles.qrSection}>
        <View style={styles.qrContainer}>
          <Text style={styles.qrLabel}>Ladda ner vår app</Text>
          <Image 
            style={styles.qrCode}
            src="/get-app-qr-code.png"
          />
          <Text style={styles.qrDescription}>Skanna för att ladda ner vår app</Text>
        </View>

        <View style={styles.qrContainer}>
          <Text style={styles.qrLabel}>Swish</Text>
          <Image 
            style={styles.qrCode}
            src="/swish-qr-code.png"
          />
          <Text style={styles.qrDescription}>Betala Medlemsavgiften och Sadaqa</Text>
        </View>
      </View>

      <Text style={styles.footer}>
        www.mjolbymoske.se • Mjölby Moské • {format(new Date(), 'yyyy')}
      </Text>
    </Page>
  );

  return (
    <Document>
      {months.map(renderMonthPage)}
    </Document>
  );
};

export default PrayerTimesPDF;