import { useState } from "react";
import { motion } from "framer-motion";
import { Smartphone, AlertCircle, ChevronDown, ChevronUp, HelpCircle, Mail, ShieldCheck } from "lucide-react";

// Add FAQ type
interface FAQ {
    question: string;
    answer: string;
}

export default function AppDownloadPage() {
    const [isHovered, setIsHovered] = useState(false);
    const [openFAQ, setOpenFAQ] = useState<number | null>(null);

    // FAQ data
    const faqs: FAQ[] = [
        {
            question: "Varför får jag inga notifikationer?",
            answer: "Kontrollera att du har aktiverat notifikationer både i appen och i din enhets inställningar. Gå till 'Inställningar' i appen för att justera notifikationsinställningarna."
        },
        {
            question: "Fungerar appen offline?",
            answer: "Ja, appen fungerar offline med tidigare nedladdade bönetider. För att säkerställa aktuella tider, anslut regelbundet till internet för uppdateringar."
        }
    ];

    return (
        <div className="w-full max-w-4xl mx-auto">
            <motion.h1
                className="text-3xl sm:text-5xl font-extrabold mb-4 text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Hämta vår app
            </motion.h1>

            <div className="bg-secondary shadow-xl rounded-3xl overflow-hidden">
                <div className="p-8 md:p-12">
                    <div className="flex flex-col md:flex-row gap-12 items-center">
                        <div className="flex-1 space-y-8">
                            <motion.div
                                className="flex items-center space-x-4"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <img
                                    src="/logo-pray-on-time.png"
                                    alt="Pray on Time Logo"
                                    className="w-16 h-16 rounded-xl shadow-lg"
                                />
                                <p className="text-2xl font-semibold">
                                    Pray on Time
                                </p>
                            </motion.div>

                            <motion.p
                                className="text-xl"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                Ladda ner vår Android-app för att enkelt
                                hålla koll på bönetider!
                            </motion.p>

                            <motion.div
                                className="flex items-center space-x-2 text-yellow-300"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            >
                                <AlertCircle className="w-5 h-5" />
                                <p className="text-sm">
                                    iOS-version kommer snart!
                                </p>
                            </motion.div>

                            <motion.div
                                className="flex flex-col sm:flex-row items-center space-y-6 sm:space-y-0 sm:space-x-8"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                            >
                                <img
                                    src="/pray-on-time-qr-code.png"
                                    alt="QR Code"
                                    className="w-32 h-32 bg-white p-2 rounded-lg shadow-lg"
                                />
                                <div className="space-y-4">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.osmandev.pray_on_time"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-secondary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-colors duration-300"
                                    >
                                        <Smartphone className="mr-2 h-5 w-5" />
                                        Ladda ner Android-appen
                                    </a>
                                    <p className="text-sm text-center sm:text-left">
                                        Skanna QR-koden eller klicka på
                                        knappen ovan
                                    </p>
                                </div>
                            </motion.div>
                        </div>
                        <motion.div
                            className="flex-1 relative max-w-[300px] w-full"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.8 }}
                            onHoverStart={() => setIsHovered(true)}
                            onHoverEnd={() => setIsHovered(false)}
                        >
                            <img
                                src="/pray-on-time-screenshot.png"
                                alt="App Screenshot"
                                className="w-full h-auto rounded-3xl shadow-2xl transition-transform duration-300 ease-in-out"
                                style={{
                                    transform: isHovered
                                        ? "scale(1.05) rotate(-5deg)"
                                        : "scale(1) rotate(0)",
                                }}
                            />
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Support Section */}
            <motion.div
                className="mt-12"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
            >
                <h2 className="text-3xl font-arial-rounded-bold mb-6 text-center">Support</h2>
                
                {/* FAQ Section */}
                <div className="bg-secondary rounded-3xl overflow-hidden shadow-xl mb-8">
                    <div className="p-8">
                        <h3 className="text-2xl font-arial-rounded-bold mb-6 flex items-center">
                            <HelpCircle className="mr-2" />
                            Vanliga frågor
                        </h3>
                        <div className="space-y-4">
                            {faqs.map((faq, index) => (
                                <motion.div
                                    key={index}
                                    className="bg-primary/20 rounded-xl overflow-hidden"
                                    initial={false}
                                >
                                    <button
                                        className="w-full px-6 py-4 text-left flex justify-between items-center"
                                        onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
                                    >
                                        <span className="font-arial-rounded-bold">{faq.question}</span>
                                        {openFAQ === index ? (
                                            <ChevronUp className="w-5 h-5 flex-shrink-0" />
                                        ) : (
                                            <ChevronDown className="w-5 h-5 flex-shrink-0" />
                                        )}
                                    </button>
                                    <motion.div
                                        initial={false}
                                        animate={{
                                            height: openFAQ === index ? "auto" : 0,
                                            opacity: openFAQ === index ? 1 : 0
                                        }}
                                        transition={{ duration: 0.3 }}
                                        className="overflow-hidden"
                                    >
                                        <p className="px-6 pb-4">{faq.answer}</p>
                                    </motion.div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Contact Support Section */}
                <motion.div
                    className="bg-secondary rounded-3xl p-8 shadow-xl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1.2 }}
                >
                    <h3 className="text-2xl font-arial-rounded-bold mb-4 flex items-center">
                        <Mail className="mr-2" />
                        Kontakta Support
                    </h3>
                    <p className="mb-4">
                        Har du andra frågor eller problem? Vi hjälper dig gärna!
                    </p>
                    <div className="bg-primary/20 rounded-xl p-6">
                        <p className="font-arial-rounded-bold mb-2">Email support:</p>
                        <a
                            href="mailto:osman.said.apps@gmail.com"
                            className="text-white hover:underline flex items-center"
                        >
                            <Mail className="w-5 h-5 mr-2" />
                            osman.said.apps@gmail.com
                        </a>
                    </div>

                    {/* Add divider */}
                    <div className="border-t border-white/10 my-6"></div>

                    {/* Privacy Policy Link */}
                    <div className="mt-4">
                        <a
                            href="/privacy-policy"
                            className="group inline-flex items-center px-4 py-2 rounded-lg bg-primary/20 hover:bg-primary/30 transition-all duration-300 ease-in-out"
                        >
                            <ShieldCheck className="w-5 h-5 mr-2 group-hover:scale-110 transition-transform duration-300" />
                            <span className="text-white group-hover:underline">
                                Läs vår fullständiga integritetspolicy för Pray on Time-appen
                            </span>
                            <svg 
                                className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </a>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}
