import { useEffect } from 'react';

const AppRedirectPage = () => {
  useEffect(() => {
    // iOS detection
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // Android detection
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      window.location.href = 'https://apps.apple.com/se/app/pray-on-time/id6741391191?l=en-GB';
    } else if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.osmandev.pray_on_time';
    } else {
      // For desktop or unknown devices, show both options
      document.body.innerHTML = `
        <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh;
          font-family: Arial, sans-serif;
          text-align: center;
          padding: 20px;
          background-color: #41729F;
          color: white;
        ">
          <h1 style="margin-bottom: 20px;">Pray on Time</h1>
          <p style="margin-bottom: 30px;">Välj din plattform:</p>
          <div style="display: flex; gap: 20px;">
            <a href="https://apps.apple.com/se/app/pray-on-time/id6741391191?l=en-GB"
               style="
                 background-color: white;
                 color: #41729F;
                 padding: 12px 24px;
                 border-radius: 8px;
                 text-decoration: none;
                 font-weight: bold;
               "
            >
              iOS App Store
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.osmandev.pray_on_time"
               style="
                 background-color: white;
                 color: #41729F;
                 padding: 12px 24px;
                 border-radius: 8px;
                 text-decoration: none;
                 font-weight: bold;
               "
            >
              Google Play
            </a>
          </div>
        </div>
      `;
    }
  }, []);

  return null;
};

export default AppRedirectPage;