import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-secondary rounded-lg p-8 text-white">
        <h1 className="text-3xl font-arial-rounded-bold mb-6">
          Integritetspolicy för "Pray on Time"
        </h1>
        <p className="text-sm mb-4">Gäller från: 2 Jan 2025</p>

        <div className="space-y-6">
          <section>
            <p className="mb-4">
              Välkommen till "Pray on Time", en bönapp specifikt framtagen för Mjölby som hjälper dig att observera dina böner vid rätt tidpunkter. 
              Denna integritetspolicy förklarar hur vi hanterar information när du använder vår app.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Insamling och Användning av Information</h2>
            <p className="mb-2">"Pray on Time" samlar in och använder följande information:</p>
            
            <h3 className="text-xl font-arial-rounded-bold mt-4 mb-2">Bönetidsdata</h3>
            <ul className="list-disc pl-6 space-y-1">
              <li>Vi hämtar bönetider från vår databas för att tillhandahålla exakta bönescheman från Mjölby Moské</li>
              <li>Denna data lagras lokalt på din enhet för offlineåtkomst</li>
              <li>Ingen personlig information samlas in under denna process</li>
            </ul>

            <h3 className="text-xl font-arial-rounded-bold mt-4 mb-2">Notifikationsinställningar</h3>
            <ul className="list-disc pl-6 space-y-1">
              <li>Dina notifikationsinställningar för varje bönetid</li>
              <li>Om notifikationer är aktiverade/inaktiverade för specifika böner</li>
              <li>Inställningar för tyst läge för notifikationer</li>
              <li>Dessa inställningar sparas lokalt på din enhet med Hive-lagring</li>
            </ul>

            <h3 className="text-xl font-arial-rounded-bold mt-4 mb-2">Enhetsbehörigheter</h3>
            <ul className="list-disc pl-6 space-y-1">
              <li>Notifikationsbehörigheter för att skicka bönetidsvarningar</li>
              <li>Dessa behörigheter kan hanteras genom din enhets inställningar</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Datalagring och Säkerhet</h2>
            
            <h3 className="text-xl font-arial-rounded-bold mt-4 mb-2">Lokal Lagring</h3>
            <ul className="list-disc pl-6 space-y-1">
              <li>Bönetider lagras lokalt med Hive för offlineåtkomst</li>
              <li>Notifikationsinställningar sparas lokalt på din enhet</li>
              <li>Ingen data lagras på externa servrar förutom bönetidsdatabasen</li>
            </ul>

            <h3 className="text-xl font-arial-rounded-bold mt-4 mb-2">Extern Data</h3>
            <ul className="list-disc pl-6 space-y-1">
              <li>Vi underhåller endast en databas med bönetider</li>
              <li>Ingen personlig användardata lagras på våra servrar</li>
              <li>Databasåtkomst är skrivskyddad för användare</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Tredjepartstjänster</h2>
            <p className="mb-2">Appen använder följande tredjepartstjänster:</p>
            <ul className="list-disc pl-6 space-y-1">
              <li>Supabase: För hämtning av bönetidsdata</li>
              <li>Flutter Local Notifications: För hantering av bönetidsnotifikationer</li>
            </ul>
            <p className="mt-2">Dessa tjänster används enbart för appens funktionalitet och samlar inte in personlig användardata.</p>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Bakgrundsprocesser</h2>
            <p className="mb-2">Appen utför bakgrundsuppgifter för att:</p>
            <ul className="list-disc pl-6 space-y-1">
              <li>Schemalägga bönetidsnotifikationer</li>
              <li>Uppdatera lagrade bönetider</li>
            </ul>
            <p className="mt-2">Dessa processer körs lokalt på din enhet och överför ingen personlig data.</p>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Användarrättigheter</h2>
            <p className="mb-2">Du har rätt att:</p>
            <ul className="list-disc pl-6 space-y-1">
              <li>Aktivera/inaktivera notifikationer</li>
              <li>Välja vilka böner du vill få notifikationer för</li>
              <li>Rensa lagrade bönetidsdata</li>
              <li>Hantera appbehörigheter genom din enhets inställningar</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-arial-rounded-bold mb-3">Kontaktinformation</h2>
            <p>
              För frågor eller funderingar om din integritet när du använder "Pray on Time", vänligen kontakta: 
              <a href="mailto:osman.said.apps@gmail.com" className="underline ml-1">
                osman.said.apps@gmail.com
              </a>
            </p>
          </section>

          <footer className="mt-8 pt-4 border-t border-white/20 text-sm">
            <p>
              Denna integritetspolicy uppdaterades senast den 2 Jan 2025 för att återspegla appens nuvarande 
              funktionalitet.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;