import React, { useState, useCallback, useEffect, useRef } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { ChevronLeft, ChevronRight, Download, ChevronDown } from 'lucide-react';
import { pdf } from '@react-pdf/renderer';
import { fetchPrayerTimes } from '../utils/api';
import { PrayerTime, PrayerTimesData } from '../utils/types';
import { Popover } from '../components/Popover';
import PrayerTimesPDF from '../components/PrayerTimesPDF';
import {
  getDaysInMonth,
  isFriday,
  isHijriMonthChange,
  getHijriDate,
  getHijriMonths,
  formatMonthYear,
  formatDayMonth
} from '../utils/monthUtils';

interface CalendarPageProps {
  prayerTimesData: PrayerTimesData;
  onDateSelect: (date: Date) => void;
}

interface DownloadOption {
  label: string;
  months: number;
}

const CalendarPage: React.FC<CalendarPageProps> = () => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [monthData, setMonthData] = useState<PrayerTimesData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const monthlyCache = useRef<Record<string, PrayerTimesData>>({});

  const daysInMonth = getDaysInMonth(currentMonth);
  const today = new Date();

  const downloadOptions: DownloadOption[] = [
    { label: 'Denna månad', months: 1 },
    { label: 'Fyra månader', months: 4 },
    { label: 'Hela året', months: 12 },
  ];

  const loadMonthData = useCallback(async (date: Date) => {
    const cacheKey = format(date, 'yyyy-MM');
    
    if (monthlyCache.current[cacheKey]) {
      setMonthData(monthlyCache.current[cacheKey]);
      return;
    }

    setIsLoading(true);
    try {
      const startDate = format(startOfMonth(date), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(date), 'yyyy-MM-dd');
      
      const prayerTimes = await fetchPrayerTimes(startDate, endDate);
      
      const formattedData: PrayerTimesData = {};
      prayerTimes.forEach((pt: PrayerTime) => {
        formattedData[pt.date] = {
          Fajr: pt.fajr,
          Sunrise: pt.sunrise,
          Dhuhr: pt.dhuhr,
          Asr: pt.asr,
          Maghrib: pt.maghrib,
          Isha: pt.isha,
        };
      });
      
      monthlyCache.current[cacheKey] = formattedData;
      setMonthData(formattedData);
    } catch (error) {
      console.error('Error loading prayer times:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadMonthData(currentMonth);
  }, [currentMonth, loadMonthData]);

  const handleMonthChange = (direction: 'prev' | 'next') => {
    setCurrentMonth(prev => direction === 'prev' ? subMonths(prev, 1) : addMonths(prev, 1));
  };

  // In CalendarPage.tsx, update the handleMultiMonthDownload function:
const handleMultiMonthDownload = async (option: DownloadOption) => {
  setIsDownloading(true);
  try {
    const monthsData = [];
    
    for (let i = 0; i < option.months; i++) {
      const monthDate = new Date(currentMonth);
      monthDate.setMonth(monthDate.getMonth() + i);
      const monthKey = format(monthDate, 'yyyy-MM');
      
      if (!monthlyCache.current[monthKey]) {
        const startDate = format(startOfMonth(monthDate), 'yyyy-MM-dd');
        const endDate = format(endOfMonth(monthDate), 'yyyy-MM-dd');
        const prayerTimes = await fetchPrayerTimes(startDate, endDate);
        
        const formattedData: PrayerTimesData = {};
        prayerTimes.forEach((pt: PrayerTime) => {
          formattedData[pt.date] = {
            Fajr: pt.fajr,
            Sunrise: pt.sunrise,
            Dhuhr: pt.dhuhr,
            Asr: pt.asr,
            Maghrib: pt.maghrib,
            Isha: pt.isha,
          };
        });
        
        monthlyCache.current[monthKey] = formattedData;
      }

      monthsData.push({
        date: monthDate,
        data: monthlyCache.current[monthKey],
        daysInMonth: new Date(
          monthDate.getFullYear(),
          monthDate.getMonth() + 1,
          0
        ).getDate(),
      });
    }

    const blob = await pdf(<PrayerTimesPDF months={monthsData} />).toBlob();
    
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const startMonth = format(currentMonth, 'yyyy-MM');
    const endMonth = format(monthsData[monthsData.length - 1].date, 'yyyy-MM');
    a.download = option.months === 1 
      ? `bönetider-${startMonth}.pdf`
      : `bönetider-${startMonth}-till-${endMonth}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

  } catch (error) {
    console.error('Error downloading PDF:', error);
  } finally {
    setIsDownloading(false);
    setIsDownloadMenuOpen(false);
  }
};

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto p-4">
        <div className="w-full bg-secondary rounded-xl p-8 text-center">
          Laddar bönetider...
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      {/* Month Navigation with Download Button */}
      <div className="bg-secondary rounded-xl p-6 mb-6">
        <div className="flex flex-col items-center gap-2">
          <div className="flex items-center justify-between w-full">
            <button
              onClick={() => handleMonthChange('prev')}
              className="p-2 hover:bg-primary/30 rounded-full transition-colors"
            >
              <ChevronLeft size={24} />
            </button>
            
            <div className="flex flex-col items-center">
              <h2 className="text-2xl font-arial-rounded-bold">
                {formatMonthYear(currentMonth)}
              </h2>
              <span className="text-sm text-gray-400">
                {getHijriMonths(currentMonth, daysInMonth)}
              </span>
            </div>
            
            <div className="flex items-center gap-2">
              <button
                ref={setDownloadAnchorEl}
                onClick={() => setIsDownloadMenuOpen(true)}
                disabled={isLoading || isDownloading}
                className="p-2 hover:bg-primary/30 rounded-lg transition-colors bg-primary/20 flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isDownloading ? (
                  <span className="animate-spin">⏳</span>
                ) : (
                  <Download size={20} />
                )}
                <span className="hidden sm:inline">Ladda ner PDF</span>
                <ChevronDown size={16} className="hidden sm:block" />
              </button>
              
              <Popover
                isOpen={isDownloadMenuOpen}
                onClose={() => setIsDownloadMenuOpen(false)}
                anchorEl={downloadAnchorEl}
              >
                <div className="py-2">
                  {downloadOptions.map((option) => (
                    <button
                      key={option.months}
                      onClick={() => handleMultiMonthDownload(option)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 transition-colors"
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </Popover>

              <button
                onClick={() => handleMonthChange('next')}
                className="p-2 hover:bg-primary/30 rounded-full transition-colors"
              >
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Prayer Times Table */}
      <div className="bg-secondary rounded-xl overflow-hidden shadow-lg">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-white/10">
                <thead className="bg-primary/30">
                  <tr>
                    <th scope="col" className="sticky left-0 z-20 bg-inherit px-3 py-4 text-left text-sm font-arial-rounded-bold">
                      Datum
                    </th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Fajr</th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Soluppgång</th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Dhuhr</th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Asr</th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Maghrib</th>
                    <th scope="col" className="px-3 py-4 text-center text-sm font-arial-rounded-bold">Isha</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-white/10 bg-secondary">
                  {Array.from({ length: daysInMonth }).map((_, index) => {
                    const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), index + 1);
                    const dateString = format(date, 'yyyy-MM-dd');
                    const prayerTimes = monthData[dateString];
                    const isToday = format(today, 'yyyy-MM-dd') === dateString;
                    
                    return (
                      <tr
                        key={dateString}
                        className={`
                          transition-colors duration-200
                          ${isToday ? 'bg-primary/30' : ''}
                          ${isFriday(date) ? 'bg-blue-500/20' : ''}
                          ${isHijriMonthChange(date) ? 'bg-green-500/20' : ''}
                          ${!isToday && !isFriday(date) && !isHijriMonthChange(date) ? 'hover:bg-primary/20' : ''}
                        `}
                      >
                        <td className="sticky left-0 z-10 whitespace-nowrap px-3 py-4 text-sm bg-inherit">
                          <div className="flex flex-col">
                            <div className="flex items-center space-x-2">
                              <span>{formatDayMonth(date)}</span>
                              {isToday && (
                                <span className="text-xs bg-white/20 px-2 py-0.5 rounded-full">
                                  Idag
                                </span>
                              )}
                              {isFriday(date) && (
                                <span className="text-xs bg-blue-500/20 px-2 py-0.5 rounded-full">
                                  Fredag
                                </span>
                              )}
                            </div>
                            <div className="text-xs text-gray-400">
                              {(() => {
                                const hijri = getHijriDate(date);
                                return isHijriMonthChange(date) 
                                  ? `1 ${hijri.month} ${hijri.year}` 
                                  : `${hijri.day} ${hijri.month}`;
                              })()}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Fajr?.slice(0, 5)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Sunrise?.slice(0, 5)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Dhuhr?.slice(0, 5)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Asr?.slice(0, 5)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Maghrib?.slice(0, 5)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center">{prayerTimes?.Isha?.slice(0, 5)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;