import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { toHijri } from 'hijri-converter';

const hijriMonths = [
  'Muharram', 'Safar', 'Rabi al-Awwal', 'Rabi al-Thani',
  'Jumada al-Ula', 'Jumada al-Thani', 'Rajab', 'Shaban',
  'Ramadan', 'Shawwal', 'Dhu al-Qadah', 'Dhu al-Hijjah'
];

export const getDaysInMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const isFriday = (date: Date): boolean => date.getDay() === 5;

export const isHijriMonthChange = (date: Date): boolean => {
  const currentDay = toHijri(date.getFullYear(), date.getMonth() + 1, date.getDate());
  const previousDay = toHijri(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate() - 1
  );
  return currentDay.hm !== previousDay.hm;
};

export const getHijriDate = (date: Date) => {
  const hijriDate = toHijri(date.getFullYear(), date.getMonth() + 1, date.getDate());
  return {
    day: hijriDate.hd,
    month: hijriMonths[hijriDate.hm - 1],
    year: hijriDate.hy
  };
};

export const getHijriMonths = (date: Date, daysInMonth: number) => {
  const firstDay = toHijri(date.getFullYear(), date.getMonth() + 1, 1);
  const lastDay = toHijri(date.getFullYear(), date.getMonth() + 1, daysInMonth);

  if (firstDay.hm === lastDay.hm) {
    return `${hijriMonths[firstDay.hm - 1]} ${firstDay.hy}`;
  }

  return `${hijriMonths[firstDay.hm - 1]} - ${hijriMonths[lastDay.hm - 1]} ${firstDay.hy}`;
};

export const getHijriDayInfo = (date: Date): string => {
  if (isHijriMonthChange(date)) {
    const hijriDate = toHijri(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return `(1 ${hijriMonths[hijriDate.hm - 1]})`;
  }
  return '';
};

export const formatMonthYear = (date: Date): string => {
  return format(date, 'MMMM yyyy', { locale: sv });
};

export const formatDayMonth = (date: Date): string => {
  return format(date, 'd MMMM', { locale: sv });
};

export const formatShortDayMonth = (date: Date): string => {
  return format(date, 'd MMM', { locale: sv });
};