import React, { useRef, useEffect } from 'react';

interface PopoverProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
}

export const Popover: React.FC<PopoverProps> = ({ isOpen, onClose, children, anchorEl }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !anchorEl) return null;

  const rect = anchorEl.getBoundingClientRect();

  return (
    <div
      ref={popoverRef}
      className="fixed z-50 mt-2 bg-white rounded-lg shadow-xl border border-gray-200 text-primary"
      style={{
        top: rect.bottom + window.scrollY + 8,
        left: rect.left + window.scrollX,
      }}
    >
      {children}
    </div>
  );
};